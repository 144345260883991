import { Box, Typography } from "@mui/material"
import React from "react"
import HousingPic from "../assets/US-Housing-Price-Index-Since-1900.jpg"
import Footer from "../components/Footer"

const About = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom right, black, #B03A2E)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ padding: "20px 20px 60px 20px" }}>
        <Typography variant="h2" sx={{ textAlign: "center", color: "white" }}>
          The App
        </Typography>
        <Box className="row col-md-6 mb-4">
          <Typography variant="h3" color={"white"}>
            Our Mission
          </Typography>
          <Typography color={"white"}>
            Build a web-app to expedite Real Estate Investment decisions with
            simplified ROI calculations through comprehensive Neighborhood
            appreciation analysis
          </Typography>
          <Typography variant="h4" sx={{ marginTop: "12px" }} color={"white"}>
            History
          </Typography>
          <Typography color={"white"}>
            The TI-83 calculator that we all grew up with was a fantastic tool
            to solve and graph complex problems such as 2cosx+√3=0. But as we've
            aged, it's become very apparent that nobody cares about
            2cosx+√3=0. Even the meme gods would agree that we should've been
            taught about how to budget, how to do our taxes, how the stock
            market works, and communication skills. Most importantly, what we
            should've been taught about, is the value and importance of
            investing into the Real Estate market.
          </Typography>
          <Typography variant="h4" sx={{ marginTop: "12px" }} color={"white"}>
            Investing
          </Typography>
          <Typography color={"white"}>
            Our major news media outlets obsess over stories that promote fear.
            Rightfully so, as fear keeps users engaged to see "what happens
            next," which drives ratings and ultimately profits, along with any
            other ancillary motives or agendas they advocate for. So it's no
            shock that when talking to others about Real Estate investing, 90%
            of the time, their response will be, "aren't you worried about the
            market crashing again?!" To which my answer, and your answer, should
            always be, "No."
          </Typography>
          <Typography color={"white"}>
            Like any investment, there is always a risk involved, especially on
            quick "flips". But when thinking critically about what a quick flip
            really is, it's not a true investment. It's really just gambling and
            hoping to make a quick buck. Looking at any 10 year period
            throughout the last 100+ years, (see picture) you'll see that the
            market has ALWAYS rebounded ensuring that you will in fact, make
            your money.
          </Typography>
          <Box sx={{ textAlign: "center", marginY: "12px" }}>
            <img
              src={HousingPic}
              alt="levi-kane-realtor"
              style={{
                maxWidth: "80vw",
              }}
            />
          </Box>
          <Typography variant="h4" sx={{ marginTop: "12px" }} color={"white"}>
            Why Us?
          </Typography>
          <Typography color={"white"}>
            This isn't Zillow, this isn't Redfin, this isn't even the MLS. This
            is the RE-83, where diligent research and analysis has been done and
            provided for you, the user, to use as a tool, to make your best,
            most educated, first (or next) real estate investment.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default About
