export const defaultFormState = {
  downPaymentAmount: 0,
  pmiIncluded: null,
  monthlyPayment: 0,
  leftOverMoney: 0,
  monthlyGross: 0,
  yearlyGross: 0,
  monthlyIncome: 0,
  yearlyIncome: 0,
  capRate: 0,
  cashOnCash: 0,
  moneyOnHand: null,
  purchasePrice: null,
  annualTax: null,
  downPaymentPercentage: "3.5",
  interestRate: "6.5",
  termInYears: 30,
  monthlyInsurance: 175,
  monthlyUtilities: 400,
  miscExpenses: 100,
}

export const formReducer = (state, action) => {
  switch (action.type) {
    case "ADD_DOWN_PAYMENT":
      return { ...state, downPayment: action.downPayment }
    case "ADD_MORTGAGE_FORM_DATA":
      const formData = action.payload
      return {
        ...state,
        mortgageFormData: {
          downPaymentAmount: formData.downPaymentAmount,
          pmiIncluded: formData.pmiIncluded,
          monthlyPayment: formData.monthlyPayment,
          leftOverMoney: formData.leftOverMoney,
          monthlyGross: formData.monthlyGross,
          yearlyGross: formData.yearlyGross,
          monthlyIncome: formData.monthlyIncome,
          yearlyIncome: formData.yearlyIncome,
          capRate: formData.capRate,
          cashOnCash: formData.cashOnCash,
          moneyOnHand: formData.moneyOnHand,
          purchasePrice: formData.purchasePrice,
          annualTax: formData.annualTax,
          downPaymentPercentage: formData.downPaymentPercentage,
          interestRate: formData.interestRate,
          termInYears: formData.termInYears,
          monthlyInsurance: formData.monthlyInsurance,
          monthlyUtilities: formData.monthlyUtilities,
          miscExpenses: formData.miscExpenses,
        },
      }

    default:
      return defaultFormState
  }
}
