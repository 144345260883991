import React from "react"
import { Box, Typography } from "@mui/material"

const Header = () => {
  return (
    <Box
      className="container"
      sx={{ marginRight: "0px", padding: ".5rem 1.5rem" }}
    >
      <Box className=" text-end" sx={{ textAlign: "right" }}>
        <Typography
          sx={{
            marginBottom: ".25rem",
            fontSize: "26px",
            // fontWeight: 300,
            lineHeight: 1.2,
            fontFamily: "var(--bs-font-sans-serif)",
          }}
        >
          Good things come to those who wait
        </Typography>
        <Typography
          variant=""
          sx={{
            fontSize: "28px",
            fontWeight: 900,
            marginBottom: "1rem",
            lineHeight: "32px",
            fontFamily: "var(--bs-font-sans-serif)",
          }}
        >
          But only the things left by those who hustle.
        </Typography>
      </Box>
    </Box>
  )
}

export default Header
