import React, { useState } from "react"
import { TextField, Box, FormControl, Button, Typography } from "@mui/material"
import axios from "axios"

const CreateUser = (props) => {
  const [user, setUser] = useState({ firstName: "", lastName: "", email: "" })
  const inputFields = [
    { label: "First Name", stateKey: "firstName" },
    { label: "Last Name", stateKey: "lastName" },
    { label: "Email", stateKey: "email" },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Post request to add user
    await axios
      .post("https://levis-roi-app.onrender.com/users/add", user)
      .then((res) => console.log("Posting: ", res.data))
      .catch((error) => console.log("Uh oh! You have an error, dude: ", error))

    // Fetch all users
    await axios
      .get("https://levis-roi-app.onrender.com/users/get")
      .then((res) => {
        const userData = res.data
        // Filter for the user with the matching email
        const userWithMatchingEmail = userData.find(
          (u) => u.email === user.email
        )
        if (userWithMatchingEmail) {
          console.log("Matched User ID: ", userWithMatchingEmail._id)
          localStorage.setItem("levis-user-id", userWithMatchingEmail._id)
        } else {
          console.log("No user found with the email: ", user.email)
        }
      })
      .catch((error) => console.log("Uh oh! You have an error, dude: ", error))

    props.handleClose()
  }

  return (
    <Box>
      <Typography
        style={{
          border: "2px solid black",
          borderRadius: "15px",
          padding: "10px",
        }}
      >
        If you want to save your calculations for future reference, we'll need
        to gather some info first!
      </Typography>
      <Typography fontSize={10}>
        (Your information will NEVER be sold or made public and is used strictly
        for account verification)
      </Typography>
      <Box sx={{ padding: "20px 0" }}>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            {inputFields.map((field) => (
              <TextField
                key={field.stateKey}
                fullWidth
                required
                id={`outlined-${field.stateKey}`}
                label={field.label}
                value={user[field.stateKey]}
                type={field.label === "Email" ? "email" : "text"}
                onChange={(event) =>
                  setUser({ ...user, [field.stateKey]: event.target.value })
                }
                sx={{ margin: ".5rem 0" }}
              />
            ))}
            <Button
              sx={{ borderRadius: "20px", marginTop: ".5rem" }}
              variant="contained"
              color="success"
              type="submit"
            >
              Lets crunch some numbers!
            </Button>
          </FormControl>
        </form>
      </Box>
    </Box>
  )
}

export default CreateUser
