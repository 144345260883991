import * as React from "react"
import { Link } from "react-router-dom"
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Toolbar,
  Typography,
  Button,
  Collapse,
  Menu,
  MenuItem,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import CloseIcon from "@mui/icons-material/Close"

const drawerWidth = "100%"
const navItems = [
  { name: "Home", path: "/" },
  {
    name: "About",
    nestedList: [
      { name: "The App", path: "/about" },
      { name: "Our Story", path: "/our-story" },
    ],
  },
  { name: "Calculators", path: "/calculator-list" },
  { name: "Market Trends", path: "/market-trends" },
  { name: "Book a Call!", path: "/book-an-appointment" },
]

function NavBar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [openDropdown, setOpenDropdown] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleDropdownToggle = (item) => {
    setOpenDropdown((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }))
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
    setOpenDropdown(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderNavItem = (item) => (
    <React.Fragment key={item.name}>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            textAlign: "start",
            color: "white",
          }}
          onClick={() => item.nestedList && handleDropdownToggle(item.name)}
        >
          <ListItemText primary={item.name} />
          {item.nestedList ? (
            openDropdown[item.name] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItemButton>
      </ListItem>
      {item.nestedList && (
        <Collapse in={openDropdown[item.name]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.nestedList.map((nestedItem) => (
              <Link
                key={nestedItem.name}
                to={nestedItem.path}
                style={{ textDecoration: "none", color: "white" }}
                onClick={handleDrawerToggle}
              >
                <ListItemButton
                  key={nestedItem.name}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    paddingLeft: "24px",
                  }}
                >
                  <KeyboardArrowRightIcon sx={{ transform: "scale(0.8)" }} />
                  <ListItemText primary={nestedItem.name} />
                </ListItemButton>
              </Link>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )

  const drawer = (
    <Box
      sx={{
        textAlign: "center",
        background: "linear-gradient(to bottom right, #B03A2E, black )",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "white",
          paddingX: "20px",
        }}
      >
        <Typography
          color={"white"}
          sx={{ my: 2, fontSize: "2rem", fontWeight: 600, flexGrow: 1 }}
        >
          Explore
        </Typography>
        <CloseIcon onClick={() => handleDrawerToggle()} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) =>
          item.nestedList ? (
            renderNavItem(item)
          ) : (
            <Link
              key={item.name}
              to={item.path || "#"}
              style={{ textDecoration: "none", color: "white" }}
              onClick={handleDrawerToggle}
            >
              {renderNavItem(item)}
            </Link>
          )
        )}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="sticky"
        sx={{ backgroundColor: "#2C2C2C" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            to="/"
            style={{ textDecoration: "none", color: "white", flexGrow: 1 }}
          >
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Real Estate-ROI
            </Typography>
          </Link>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <React.Fragment key={item.name}>
                {item.nestedList ? (
                  <React.Fragment>
                    <Button
                      sx={{ color: "#fff" }}
                      onClick={handleClick}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      {item.name}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {item.nestedList.map((nestedItem) => (
                        <MenuItem onClick={handleClose} key={nestedItem.name}>
                          <Link
                            to={nestedItem.path}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {nestedItem.name}
                          </Link>
                        </MenuItem>
                      ))}
                    </Menu>
                  </React.Fragment>
                ) : (
                  <Link
                    to={item.path}
                    style={{ textDecoration: "none", color: "white" }}
                    key={item.name}
                  >
                    <Button sx={{ color: "#fff" }}>{item.name}</Button>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              // Adjust these values as needed to create a more pronounced effect
              borderTopRightRadius: "0%",
              borderBottomRightRadius: "100%",
              // Optional: Apply a skew transform for a slanted effect
              // Note: This will also skew the content inside the drawer
              transform: "skewY(-50deg)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  )
}
export default NavBar
