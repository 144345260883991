import { Box, Typography } from "@mui/material"
import React from "react"

function BookAnAppointment() {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom right, black, #B03A2E)",
        height: "100%",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
        >
          Book An Appointment!
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "10px",
          }}
        >
          <iframe
            title="bookAppt"
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0J9JjfqNj5O2bD1ZQBQFjHusyXNsyFb2fdCqw1b1zMUfqmNC6oRY5fm-Rn0-J2kqIh_TlTBuUL?gv=true"
            style={{ border: 0 }}
            width="100%"
            height="700px"
            frameborder="0"
          ></iframe>
        </Box>
      </Box>
    </Box>
  )
}

export default BookAnAppointment
