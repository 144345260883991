import React from "react"

const initialState = {
  //when setting this as an entire object, i would name this payload
  mortgageFormData: {},
  addMortgageFormData: (_payload) => {}

}

const FormContext = React.createContext(initialState)
export default FormContext
