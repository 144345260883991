import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionActions from "@mui/material/AccordionActions"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import { Box, Typography } from "@mui/material"
import pages from "./pages.json"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"

const CalculatorList = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom right, black, #B03A2E)",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontSize: "3rem",
            fontWeight: "600",
            color: "white",
          }}
        >
          Calculators
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "600",
            color: "white",
          }}
        >
          Simplify your next investment...
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          {pages.map((page, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Link to={page.path} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.5rem",

                      color: "black",
                    }}
                  >
                    {page.name}
                  </Typography>
                </Link>
              </AccordionSummary>
              <AccordionDetails>{page.description}</AccordionDetails>
              <AccordionActions>
                <Link to={page.path}>
                  <Button variant="contained" color="success">
                    Use
                  </Button>
                </Link>
              </AccordionActions>
            </Accordion>
          ))}
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default CalculatorList
