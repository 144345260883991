import { Box, Divider, Typography } from "@mui/material"
import React from "react" // { useContext }
// import FormContext from "../contexts/form-context"
import ReactGa from "react-ga"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"

const OurStory = () => {
  // const formCtx = useContext(FormContext)
  // return <div>{formCtx?.mortgageFormData?.purchasePrice || "hello"}</div>
  const podcastHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Podcast Click",
    })
  }
  const contactHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Link from About to Contact page",
    })
  }
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom right, black, #B03A2E)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ padding: "20px 20px 60px 20px" }}>
        <Typography variant="h2" sx={{ textAlign: "center", color: "white" }}>
          Our Story
        </Typography>
        <Box
          sx={{
            padding: "24px",
            gap: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography style={{ fontSize: "1.2rem" }} color={"white"}>
              "Bigger Pockets," "House-Hacking," "Flipping houses,"
              "Gut-Rehabs," etc. They're the most common buzz words that I hear
              on a day to day basis. HG-TV will have you thinking that a
              gut-rehab will only take a week with only 1 small mistake. Or
              maybe you "know a guy" that flips houses and he "makes bank."
              Listen to a few episodes of Bigger Pockets you'll be convinced
              that you have it all figured out because they have all the answers
              to all the questions you might have! Or maybe you hear the term
              "House-Hack," and it makes things sound cool because you found
              some sort of cheat code.
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }} color={"white"}>
              Whatever the case may be, I'm hear to tell you that anything you
              hear is all a load of shit. I've been doing this for over 5 years
              and just like every entrepreneurship, I'm still figuring things
              out as I go. I can't tell you that anything is for certain except
              for <strong>one thing:</strong>{" "}
              <i>Anyone can do it, but not everyone will.</i>
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }} color={"white"}>
              When my wife and I started this endeavor, all that we wanted to do
              was just be able to pay for daycare.{" "}
              <strong>
                When we weren't even making a combined $100,000/year base salary
              </strong>
              , we decided that buying a Multi-family would be the only option
              for us towards <i>a life worth living.</i> 5 years, 2 kids, 1
              pandemic, 2 career shifts and a hell of a lot of side hustles
              later, we STILL just barely make a combined base income of just
              over $200,000.
              <i> However</i>, by following the plan laid forth, and staying{" "}
              <i>true</i> to the <strong>ultimate goal, </strong>
              we've been able to acquire 4 Multi-Family properties that produce
              enough rental income to pay for themselves, <i>plus</i> our own
              single family home in the nicest neighborhood in the entire state.
            </Typography>
          </Box>
          {/* <hr /> */}
          <Divider
            sx={{
              height: "12px",
              borderRadius: "20px",
              background: "linear-gradient(to bottom right, grey, white)",
            }}
          />
          <Box>
            <Typography style={{ fontSize: "1.2rem" }} color={"white"}>
              On paper, this was one of the{" "}
              <strong>easiest things we've ever done.</strong> In reality, this
              was probably one of the{" "}
              <i>hardest things that we've ever done.</i> But what kind of
              satisfaction would one get if something so monumental was to be
              achieved with such ease? Technically, yes - we have
              "House-Hacked," our way to financial freedom in 5 years. But I
              wouldn't call them <i>5 short years</i>. They were long, hard and
              completely out of place. But in order achieve something that
              you're not even sure that you can achieve, you have to be willing
              to stand out in crowds that you normally wouldn't stand in to
              begin with.
            </Typography>
            <Typography style={{ fontSize: "1.2rem" }} color={"white"}>
              Just a few short weeks after purchasing our Single Family Home, my
              wife and I sat down with my managing broker to talk about our
              journey on his podcast.{" "}
              <a
                href="https://open.spotify.com/episode/31lq0Qt1xYW47h9tYHsnGC?si=tF72hkNFT9Cj5Tpj4Yk5mg&fbclid=IwAR3LgYUP_bWFmwAr3b74VWjlQJWT9BNpLAvftmbWo8UPCbUs8HVgg2sypzg&nd=1"
                target="blank"
                onClick={podcastHandler}
                style={{ color: "white" }}
              >
                Real Life Investing.
              </a>{" "}
              I would encourage any couple who is thinking about this endeavour
              to give it a listen. Furthermore, if you're ever interested in
              learning about the process directly from me and diving into the
              spreadsheet that my wife calls, "The oracle," my door is always
              open.{" "}
              <Link
                onClick={contactHandler}
                to={"/book-an-appointment"}
                style={{ color: "white" }}
              >
                (Schedule a time to talk right here.)
              </Link>{" "}
              But whatever your situation may be, <i>always</i> remember that{" "}
              <i>anyone</i> has the option <strong>to live for free.</strong>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default OurStory
