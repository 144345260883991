import React from "react"
import { Box, useTheme, useMediaQuery } from "@mui/material"
import landlordSpecial from "../assets/landlord_special.jpeg"
import MobileQuickNav from "../components/MobileQuickNav"
import DesktopQuickNav from "../components/DesktopQuickNav"

const Home = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box
      sx={{
        backgroundImage: `url("${landlordSpecial}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isMobileScreen ? <MobileQuickNav /> : <DesktopQuickNav />}
    </Box>
  )
}

export default Home
