import React from "react"
import { formReducer, defaultFormState } from "./formReducer"
import FormContext from "./form-context"

const FormProvider = (props) => {
  const [formState, dispatchFormAction] = React.useReducer(
    formReducer,
    defaultFormState
  )
  // const addingDownPayment = (_downPayment) => {
  //   dispatchFormAction({ type: "ADD_DOWN_PAYMENT", downPayment: _downPayment })
  // }
  const addingMortgageFormData = (_payload) => {
    dispatchFormAction({ type: "ADD_MORTGAGE_FORM_DATA", payload: _payload })
  }
  const formContext = {
    // downPayment: formState.downPayment,
    // addDownPayment: addingDownPayment,
    mortgageFormData: formState.mortgageFormData,
    addMortgageFormData: addingMortgageFormData,
  }
  return (
    <FormContext.Provider value={formContext}>
      {props.children}
    </FormContext.Provider>
  )
}

export default FormProvider
