import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import PageDescriptionCard from "./PageDescriptionCard"

const CalculatorTemplate = ({
  descriptionChildren,
  children,
  pageTitle,
  pageDescriptionTitle,
}) => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom right, black, #B03A2E)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          padding: isMobileScreen ? "20px" : "20px 20%",
          gap: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" sx={{ textAlign: "center", color: "white" }}>
          {pageTitle}
        </Typography>
        <PageDescriptionCard title={pageDescriptionTitle}>
          {descriptionChildren}
        </PageDescriptionCard>
        {children}
      </Box>
    </Box>
  )
}

export default CalculatorTemplate
