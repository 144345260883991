import React, { useEffect, useState } from "react"
import CalculatorTemplate from "../components/CalculatorTemplate"
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import CalculatorFormBox from "../components/CalculatorFormBox"
import { resultsStyles, selectFieldStyles, textFieldStyles } from "./styles.css"
import increases from "../salaryIncreases.json"
import salaries from "../salaryPercentages.json"
import { Close } from "@mui/icons-material"
import axios from "axios"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Footer from "../components/Footer"

const SalaryCalculator = () => {
  const [salaryOrBuildingInput, setSalaryOrBuildingInput] = useState(null)
  const [salaryObject, setSalaryObject] = useState({
    currentSalary: null,
    desiredSalary: null,
  })
  const [buildingObject, setBuildingObject] = useState({
    rentYouAreCurrentlyPaying: null,
    projectedBuildingProfits: null,
  })
  const [newBiWeeklyPaycheck, setNewBiWeeklyPaycheck] = useState(0)
  const [currentBiWeeklyPaycheck, setCurrentBiWeeklyPaycheck] = useState(0)
  const [diffInPay, setDiffInPay] = useState(0)
  const [buildingSalary, setBuildingSalary] = useState(0)
  const [soundInvestment, setSoundInvestment] = useState(null)
  const [open, setOpen] = useState(false)
  const [buildingsList, setBuildingsList] = useState(null)
  const userId = localStorage.getItem("levis-user-id")

  useEffect(() => {
    fetchAndDisplayBuildings(userId)
  }, [userId])

  const handleSalaryChange = (e) => {
    const selectedIncrease = parseFloat(e.target.value)
    setSalaryObject((prevState) => ({
      ...prevState,
      desiredSalary: selectedIncrease,
    }))
    const newDesiredSalary = selectedIncrease + salaryObject.currentSalary
    const roundedSal = Math.round(newDesiredSalary / 5000) * 5000
    const computedTax = computeTaxAmount(roundedSal)
    const newSalaryAfterTaxes = roundedSal - computedTax
    setNewBiWeeklyPaycheck(newSalaryAfterTaxes / 26)
    setSalaryObject({ ...salaryObject, desiredSalary: selectedIncrease })
  }

  const computeTaxAmount = (selectedSalary) => {
    for (const importedSalaryObject of salaries) {
      if (importedSalaryObject.salary === selectedSalary) {
        return importedSalaryObject.tax * selectedSalary
      }
    }
    return 0
  }
  const parseValue = (value) => {
    // If the only input is a negative sign, return it as is.
    if (value === "-") {
      return value
    }
    const numericValue = value.replace(/[^\d.-]/g, "")
    return numericValue ? parseFloat(numericValue) : null
  }

  const formatValue = (value, key) => {
    if (value === null) return ""
    if (value === "-" && key === "projectedBuildingProfits") return value // Allow '-' for projectedBuildingProfits
    const numericValue = parseFloat(value)
    if (key === "desiredSalary") {
      return numericValue.toString()
    }
    const isNegative = numericValue < 0
    const formattedNumber = `$${Math.abs(numericValue).toLocaleString()}`
    return isNaN(numericValue)
      ? ""
      : isNegative
      ? `-${formattedNumber}`
      : formattedNumber
  }
  const handleInputChange = (key, value, buildingInput) => {
    // If the key is 'projectedBuildingProfits' and the input is just a '-', keep it as is.
    // Otherwise, parse the value.
    let numericValue
    if (key === "projectedBuildingProfits" && value === "-") {
      numericValue = value
    } else {
      numericValue = parseValue(value) // Parse the input value to a number
    }

    if (!buildingInput) {
      // Assuming you have logic here for other types of inputs not related to buildingInput.
      const roundedSal = Math.round(numericValue / 5000) * 5000
      const computedTax = computeTaxAmount(roundedSal)
      const currentSalAfterTaxes = numericValue - computedTax
      setCurrentBiWeeklyPaycheck(currentSalAfterTaxes / 26)
      setSalaryObject((prevState) => ({
        ...prevState,
        [key]: numericValue,
      }))
    } else {
      // The logic for buildingInput remains the same...
      setBuildingObject((prevState) => ({
        ...prevState,
        [key]: numericValue,
      }))
    }
  }

  const toSentenceCase = (str) => {
    // Split by camelCase or numbers
    const parts = str.split(/(?=[A-Z0-9])/)
    // Capitalize each part and join with space
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ")
  }

  const fetchAndDisplayBuildings = async (userId) => {
    try {
      console.log("fetching..")
      const response = await axios.get(
        `https://levis-roi-app.onrender.com/users/buildings/${userId}`
      )
      // const buildings = response.data
      console.log("FETCH IS WORKING", response.data)
      setBuildingsList(response.data)
      // Now you have the buildings, you can display them
    } catch (error) {
      console.error("Error fetching buildings:", error)
    }
  }
  const deleteBuilding = async (userId, buildingName) => {
    try {
      await axios.delete(
        `https://levis-roi-app.onrender.com/users/remove-building/${userId}`,
        { data: { buildingName } }
      )
      console.log("Building removed")
      // Optionally, refresh the list of buildings for the UI here
    } catch (error) {
      console.error("Error removing building:", error)
    }
  }
  const scrollToAccordionsRef = () => {
    setTimeout(() => {
      window.scrollBy({
        top: 800, // Vertical scroll amount in pixels
        behavior: "smooth", // Optional: smooth scroll
      })
    }, 200)
  }

  const computeDiffInPay = () => {
    setDiffInPay(newBiWeeklyPaycheck - currentBiWeeklyPaycheck)
  }

  const computeBuildingVsSalary = () => {
    const buildingSal =
      buildingObject.rentYouAreCurrentlyPaying +
      buildingObject.projectedBuildingProfits
    const payDiff = newBiWeeklyPaycheck - currentBiWeeklyPaycheck
    setBuildingSalary(buildingSal)
    setDiffInPay(payDiff)
    const difBuildingVsSalary = buildingSal - payDiff * 2
    difBuildingVsSalary > 0
      ? setSoundInvestment(true)
      : setSoundInvestment(false)
  }

  function formatNumber(num, fixed) {
    if (!num) {
      return
    }
    const fixedNum = num.toFixed(fixed)
    // Convert the fixed string back to a number, then format it with locale string
    const formattedNumber = parseFloat(fixedNum).toLocaleString()
    return formattedNumber
  }

  const pageDescription = () => {
    return (
      <>
        <Typography color={"white"}>
          Are you busting your ass to get your next raise or promotion? What
          will that pay bump mean for you, <strong>EXACTLY?</strong> Figure out
          what your next pay increase will be when it's broken down per month
          and even per pay period,{" "}
          <em>
            <strong>AFTER</strong>
          </em>{" "}
          taxes.
        </Typography>
      </>
    )
  }
  return (
    <>
      <CalculatorTemplate
        pageTitle={"Salary Calculator"}
        pageDescriptionTitle={"How it works"}
        descriptionChildren={pageDescription()}
      >
        <ButtonGroup
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: salaryOrBuildingInput ? null : "200px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => setSalaryOrBuildingInput("salary")}
          >
            Salary Calculator
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setSalaryOrBuildingInput("building")}
          >
            Salary Vs Building
          </Button>
        </ButtonGroup>
        {(salaryOrBuildingInput === "salary" ||
          salaryOrBuildingInput === "building") && (
          <CalculatorFormBox>
            {Object.keys(salaryObject).map((key) => {
              return (
                <TextField
                  required
                  fullWidth
                  select={key === "desiredSalary"}
                  key={key}
                  type={"text"}
                  value={formatValue(salaryObject[key], key)}
                  onChange={(e) => {
                    if (key === "currentSalary") {
                      handleInputChange(key, e.target.value, false)
                    } else {
                      handleSalaryChange(e)
                    }
                  }}
                  label={
                    key === "currentSalary"
                      ? toSentenceCase(key)
                      : "Your next pay increase will be..."
                  }
                  sx={
                    key === "currentSalary"
                      ? textFieldStyles
                      : selectFieldStyles
                  }
                >
                  {key === "desiredSalary" &&
                    increases.map((increase) => (
                      <MenuItem key={increase} value={increase}>
                        ${increase.toLocaleString()}
                      </MenuItem>
                    ))}
                </TextField>
              )
            })}
            {salaryOrBuildingInput === "building" &&
              Object.keys(buildingObject).map((key) => (
                <TextField
                  required
                  fullWidth
                  key={key}
                  type={"text"}
                  value={formatValue(buildingObject[key], key)}
                  onChange={(e) => handleInputChange(key, e.target.value, true)}
                  label={toSentenceCase(key)}
                  sx={textFieldStyles}
                  helperText={
                    key === "rentYouAreCurrentlyPaying" &&
                    "Enter 0 if you don't plan on living here."
                  }
                />
              ))}
            {salaryOrBuildingInput === "salary" &&
              Object.values(salaryObject).every((value) => value !== null) && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "50%", marginX: "auto" }}
                  onClick={() => {
                    setOpen(true)
                    computeDiffInPay()
                  }}
                >
                  Compute
                </Button>
              )}
            {salaryOrBuildingInput === "building" &&
              Object.values(buildingObject).every(
                (value) => value !== null && value !== "-"
              ) && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "50%", marginX: "auto" }}
                  onClick={() => {
                    setOpen(true)
                    computeBuildingVsSalary()
                  }}
                >
                  Compute
                </Button>
              )}
          </CalculatorFormBox>
        )}
        <Box>
          {buildingsList &&
            buildingsList.map((building, index) => (
              <Accordion key={building.name}>
                <AccordionSummary
                  onClick={() => {
                    scrollToAccordionsRef()
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.5rem",

                      color: "black",
                    }}
                  >
                    {building.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box sx={resultsStyles}>
                      <Typography fontWeight={600}>Down Payment:</Typography> $
                      {formatNumber(building.downPaymentAmount)}
                    </Box>
                    {building.moneyOnHand && building.leftOverMoney && (
                      <Box sx={resultsStyles}>
                        <Typography>Your left over money is </Typography>
                        {formatNumber(building.leftOverMoney)}
                      </Box>
                    )}
                    <Box sx={resultsStyles}>
                      <Typography>
                        <strong>Estimated Monthly Payment</strong> (with
                        utilities
                        {building.pmiIncluded}):{" "}
                      </Typography>{" "}
                      ${formatNumber(building.monthlyPayment)}
                    </Box>
                    <Box sx={resultsStyles}>
                      <Typography>Monthly Gross Income: </Typography>$
                      {formatNumber(building.monthlyGross)}
                    </Box>
                    <Box sx={resultsStyles}>
                      <Typography>Yearly Gross Income: </Typography>$
                      {formatNumber(building.yearlyGross)}
                    </Box>
                    <Box sx={{ paddingTop: "2rem" }}>
                      <Box sx={[resultsStyles, { border: "none" }]}>
                        <Typography variant="h6">
                          Potential Monthly NET{" "}
                          {building.monthlyIncome >= 0 ? "income:" : "payment:"}{" "}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            color: building.monthlyIncome < 1 ? "red" : "black",
                            fontWeight: 600,
                          }}
                        >
                          ${formatNumber(building.monthlyIncome)}
                        </Typography>
                      </Box>
                      <Box sx={[resultsStyles, { border: "none" }]}>
                        <Typography>Potential Yearly NET Income: </Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {formatNumber(building.yearlyIncome)}
                        </Typography>
                      </Box>
                    </Box>
                    {building.monthlyIncome > 1 && (
                      <Typography variant="caption">
                        <em>
                          That's a {building.capRate.toFixed(2)}%{" "}
                          <strong>Cap-Rate</strong> and{" "}
                          {building.cashOnCash.toFixed(2)}%{" "}
                          <strong>Cash-On-Cash</strong>
                        </em>
                      </Typography>
                    )}
                    <Box
                      sx={{
                        marginTop: "40px",
                        borderTop: "1px solid rgba(0, 0, 0, 0.15)",
                        textAlign: "center",
                      }}
                    >
                      {Object.entries(building.incomeObject).map(
                        ([key, value]) => {
                          if (key.startsWith("unit")) {
                            const unitNumber = key.replace("unit", "Unit ")
                            return (
                              <Typography key={key + value}>
                                {unitNumber} rent was ${value.toLocaleString()}
                              </Typography>
                            )
                          } else {
                            return null
                          }
                        }
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={async () => {
                      await deleteBuilding(userId, building.name)
                      // setTimeout(() => {
                      await fetchAndDisplayBuildings(userId)
                      // }, 300)
                    }}
                  >
                    Delete
                  </Button>
                </AccordionActions>
              </Accordion>
            ))}
        </Box>
        <Footer />
      </CalculatorTemplate>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Here are the numbers...</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box sx={resultsStyles}>
            <Typography>
              From a ${salaryObject.desiredSalary?.toLocaleString()} salary
              increase, your monthly net (after taxes) increase in pay is:{" "}
              <span style={{ backgroundColor: "#FFDD33", fontWeight: 600 }}>
                ${formatNumber(diffInPay * 2)}
              </span>
            </Typography>
          </Box>
          <Box sx={resultsStyles}>
            <Typography>
              Thats ${formatNumber(diffInPay)} on your bi-weekly check
            </Typography>{" "}
          </Box>
          {salaryOrBuildingInput === "building" && (
            <>
              <Box sx={resultsStyles}>
                <Typography>
                  After all expenses paid, your new monthly net{" "}
                  {buildingObject.rentYouAreCurrentlyPaying
                    ? "while living in"
                    : "from"}{" "}
                  the building:{" "}
                  <span style={{ backgroundColor: "#FFDD33", fontWeight: 600 }}>
                    {/* ${buildingSalary.toFixed(2).toLocaleString()} */}$
                    {formatNumber(buildingSalary)}
                  </span>
                </Typography>
              </Box>
              <Box sx={resultsStyles}>
                <Typography>
                  {/* Thats ${buildingSalary.toFixed(2).toLocaleString() / 2}{" "} */}
                  That ${formatNumber(buildingSalary / 2)}
                  bi-weekly
                </Typography>
              </Box>
            </>
          )}
          <Box sx={{ paddingTop: "2rem" }}>
            <Box sx={[resultsStyles, { border: "none" }]}>
              {salaryOrBuildingInput === "building" && (
                <>
                  {soundInvestment ? (
                    <Box sx={{ textAlign: "end", marginTop: "2rem" }}>
                      <Typography>
                        Buying a building{" "}
                        {buildingObject.rentYouAreCurrentlyPaying ? (
                          <em>and living in it</em>
                        ) : (
                          ""
                        )}{" "}
                        will bring you{" "}
                        <strong style={{ backgroundColor: "#FFDD33" }}>
                          more money than a $
                          {salaryObject.desiredSalary.toLocaleString()} raise
                        </strong>
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: "end", marginTop: "1rem" }}>
                      <Typography className="text-end mt-5">
                        <em>In this situation,</em>{" "}
                        <strong>
                          getting a raise of $
                          {salaryObject.desiredSalary?.toLocaleString()} would
                          be better
                        </strong>{" "}
                        because you would be making $
                        {(
                          diffInPay.toFixed(2).toLocaleString() * 2 -
                          buildingSalary.toFixed(2).toLocaleString()
                        ).toFixed(0)}{" "}
                        more per month in your increased pay than this building
                        would be making for you.
                      </Typography>
                      <Typography
                        style={{
                          lineHeight: "26px",
                          textAlign: "center",
                          padding: "20px",
                          background:
                            "linear-gradient(to bottom right, black, #B03A2E)",
                          borderRadius: "10px",
                          color: "white",
                        }}
                      >
                        But your employer can terminate your position tomorrow.
                        Your building will pay you forever until you sell it.
                        Find a building that's more profitable
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SalaryCalculator
