import { BrowserRouter, Routes, Route } from "react-router-dom"
// import CreateUser from "./components/CreateUser"
import NavBar from "./components/NavBar"
import Header from "./components/Header"
import Home from "./pages/Home"
import About from "./pages/About"
import OurStory from "./pages/OurStory"
import CalculatorList from "./pages/CalculatorList"
import BookAnAppointment from "./pages/BookAppointment"
import MarketTrends from "./pages/MarketTrends"
import SelfSufficiency from "./pages/SelfSufficiency"
import MortgageCalculator from "./pages/MortgageCalculator"
import SavingsCalculator from "./pages/SavingsCalculator"
import AirBnbCalculator from "./pages/AirBnbCalculator1"
import SalaryCalculator from "./pages/SalaryCalculator"

function App() {
  return (
    <BrowserRouter>
      <Header />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/user" element={<CreateUser />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/calculator-list" element={<CalculatorList />} />
        <Route path="/book-an-appointment" element={<BookAnAppointment />} />
        <Route path="/market-trends" element={<MarketTrends />} />
        <Route path="/mortgage-calculator" element={<MortgageCalculator />} />
        <Route path="/self-sufficiency" element={<SelfSufficiency />} />
        <Route path="/savings-calculator" element={<SavingsCalculator />} />
        <Route path="/airbnb-calculator" element={<AirBnbCalculator />} />
        <Route path="/salary-calculator" element={<SalaryCalculator />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
