import React from "react"
import CalculateIcon from "@mui/icons-material/Calculate"
import QueryStatsIcon from "@mui/icons-material/QueryStats"
import InfoIcon from "@mui/icons-material/Info"
import EventIcon from "@mui/icons-material/Event"
import { Link } from "react-router-dom"
import { Box, Fab, Tooltip } from "@mui/material"

const MobileQuickNav = () => {
  return (
    <Box
      sx={{
        height: "75vw",
        width: "75vw",
        backgroundColor: "rgb(0, 0, 0, .5)",
        borderRadius: "50%",
        display: "flex",
        position: "sticky",
        bottom: "200px",
        boxShadow: "2px 10px 15px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translate(-50%, -0%)",
        }}
      >
        <Link to="/calculator-list">
          <Tooltip title="Work the numbers" placement="bottom">
            <Fab
              data-test-id={"calculator_list"}
              size="large"
              sx={{ transform: "scale(2)" }}
            >
              <CalculateIcon />
            </Fab>
          </Tooltip>
        </Link>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translate(0%, -50%)",
        }}
      >
        <Link to="/about">
          <Tooltip title="About" placement="left">
            <Fab size="large" sx={{ transform: "scale(2)" }}>
              <InfoIcon />
            </Fab>
          </Tooltip>
        </Link>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 0%)",
        }}
      >
        <Link to="/book-an-appointment">
          <Tooltip title="Book A Free Consult" placement="top">
            <Fab size="large" sx={{ transform: "scale(2)" }}>
              <EventIcon />
            </Fab>
          </Tooltip>
        </Link>
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translate(-0%, -50%)",
        }}
      >
        <Link to="/market-trends">
          <Tooltip title="See the Stats" placement="right">
            <Fab data-test-id="see_the_stats" sx={{ transform: "scale(2)" }}>
              <QueryStatsIcon />
            </Fab>
          </Tooltip>
        </Link>
      </Box>
    </Box>
  )
}

export default MobileQuickNav
