import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import CalculatorTemplate from "../components/CalculatorTemplate"
import CalculatorFormBox from "../components/CalculatorFormBox"
import { textFieldStyles, selectFieldStyles, resultsStyles } from "./styles.css"
import AddHomeIcon from "@mui/icons-material/AddHome"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import neighborhoods from "../neighborhoods.json"
import TouchAppIcon from "@mui/icons-material/TouchApp"
import mortgageHelpers from "mortgage-helpers"
import { Close } from "@mui/icons-material"
import axios from "axios"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import FormContext from "../contexts/form-context"
import { Link } from "react-router-dom"
import DomainAddIcon from "@mui/icons-material/DomainAdd"
import CreateUser from "../components/CreateUser"
import Footer from "../components/Footer"

const bedroomOptions = ["", "1 bed", "2 beds", "3 beds"]

const MortgageCalculator = () => {
  const anchorRef = React.useRef(null)
  const [selectedNeighborhood, setSelectedNeighborhood] = React.useState(null)
  const [results, setResults] = useState({
    downPaymentAmount: 0,
    pmiIncluded: null,
    monthlyPayment: 0,
    leftOverMoney: 0,
    monthlyGross: 0,
    yearlyGross: 0,
    monthlyIncome: 0,
    yearlyIncome: 0,
    capRate: 0,
    cashOnCash: 0,
  })
  const [expensesObject, setExpensesObject] = useState({
    moneyOnHand: null,
    purchasePrice: null,
    annualTax: null,
    downPaymentPercentage: null,
    interestRate: "6.5",
    termInYears: 30,
    monthlyInsurance: 220,
    monthlyUtilities: 400,
    miscExpenses: 100,
  })
  const [incomeObject, setIncomeObject] = useState({
    unit1: null,
    parkingIncome: null,
    laundryIncome: null,
  })
  const [showGraph, setShowGraph] = useState({
    house: null,
    condo: null,
    multiFamily: null,
  })
  const [neighborhoodsDropdownOpen, setNeighborhoodsDropdownOpen] = useState()
  const [selectedInputMethod, setSelectedInputMethod] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [buildingName, setBuildingName] = useState(null)
  const [buildingsList, setBuildingsList] = useState(null)
  const [openCreateUserModel, setOpenCreateUserModel] = useState(false)
  const [refsMap, setRefsMap] = useState({})
  const [newUnitAdded, setNewUnitAdded] = useState(false)
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  const userId = localStorage.getItem("levis-user-id")
  const formCtx = useContext(FormContext)
  const neighborhoodPic =
    (selectedNeighborhood && selectedNeighborhood.picture) || ""

  const checkStorage = async (key) => {
    const storedData = localStorage.getItem(key)

    if (!storedData) {
      setOpenCreateUserModel(true)
    } else {
      await axios.get(
        `https://levis-roi-app.onrender.com/users/get/${storedData}`
      )
    }
  }
  const handleClose = () => {
    setOpenCreateUserModel(false)
  }
  useEffect(() => {
    // When the dialog opens, remove padding-right from the body
    if (dialogOpen) {
      const originalStyle = window.getComputedStyle(document.body).paddingRight
      document.body.style.paddingRight = "0px"

      // When the dialog closes, restore the original padding-right
      return () => {
        document.body.style.paddingRight = originalStyle
      }
    }
    if (userId && buildingsList === null) {
      fetchAndDisplayBuildings(userId)
    }
  }, [dialogOpen, userId, buildingsList])

  let transform = {}
  neighborhoods.map((hood) => {
    const hoodKey = hood.name
    return (transform[hoodKey] = hood)
  })

  const addUnit = () => {
    setNewUnitAdded(true)
    const nextUnitNumber =
      Object.keys(incomeObject).filter((key) => key.startsWith("unit")).length +
      1
    const newUnitKey = `unit${nextUnitNumber}`
    const newIncomeObject = Object.entries(incomeObject).reduce(
      (acc, [key, value]) => {
        if (key === "parkingIncome") {
          acc[newUnitKey] = null // Add new unit key-value pair before parkingIncome
        }
        acc[key] = value // Add existing key-value pairs
        return acc
      },
      {}
    )
    const updatedRefsMap = { ...refsMap, [newUnitKey]: React.createRef() }
    setRefsMap(updatedRefsMap)
    setIncomeObject(newIncomeObject)
  }

  // Use useEffect to focus on the new unit whenever incomeObject changes
  useEffect(() => {
    if (newUnitAdded) {
      const lastUnitKey = Object.keys(incomeObject)
        .filter((key) => key.startsWith("unit"))
        .pop()
      if (lastUnitKey && refsMap[lastUnitKey] && refsMap[lastUnitKey].current) {
        refsMap[lastUnitKey].current.focus()
      }
      setNewUnitAdded(false) // Reset after focusing
    }
  }, [newUnitAdded, incomeObject, refsMap])

  const deleteUnit = () => {
    // Find the highest unit number by filtering keys that start with "unit"
    // and then mapping to extract the number part and converting it to a number
    const unitNumbers = Object.keys(incomeObject)
      .filter((key) => key.startsWith("unit"))
      .map((key) => parseInt(key.replace("unit", ""), 10))
      .sort((a, b) => a - b) // Sort numerically to ensure correct order

    // Find the highest unit number
    const highestUnitNumber = unitNumbers.pop() // Removes and returns the last element (highest number)

    if (highestUnitNumber !== undefined) {
      // Clone the incomeObject without the highest unit
      const { [`unit${highestUnitNumber}`]: _, ...newIncomeObject } =
        incomeObject

      // Update state without the deleted unit
      setIncomeObject(newIncomeObject)
    }
  }

  const getBuildingData = async (buildingName) => {
    try {
      const response = await axios.get(
        `https://levis-roi-app.onrender.com/users/buildings/${userId}`
      )
      const building = response.data.find((b) => b.name === buildingName)
      console.log({ building })
      if (building) {
        setExpensesObject({
          moneyOnHand: building.expensesObject.moneyOnHand || null,
          purchasePrice: building.expensesObject.purchasePrice || null,
          annualTax: building.expensesObject.annualTax || null,
          downPaymentPercentage:
            building.expensesObject.downPaymentPercentage || null,
          interestRate: building.expensesObject.interestRate || "6.5",
          termInYears: building.expensesObject.termInYears || 30,
          monthlyInsurance: building.expensesObject.monthlyInsurance || 220,
          monthlyUtilities: building.expensesObject.monthlyUtilities || 400,
          miscExpenses: building.expensesObject.miscExpenses || 100,
        })

        const unitFields = {}
        Object.keys(building.incomeObject).forEach((key) => {
          if (key.startsWith("unit")) {
            unitFields[key] = building.incomeObject[key] || null
          }
        })

        const newIncomeObject = {
          ...unitFields,
          parkingIncome: building.incomeObject.parkingIncome || null,
          laundryIncome: building.incomeObject.laundryIncome || null,
        }

        setIncomeObject(newIncomeObject)
      }
    } catch (error) {
      console.error("Error fetching building data:", error)
    }
  }

  const toSentenceCase = (str) => {
    // Split by camelCase or numbers
    const parts = str.split(/(?=[A-Z0-9])/)
    // Capitalize each part and join with space
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ")
  }

  const handleToggleHoodDropdown = () => {
    setNeighborhoodsDropdownOpen((prevOpen) => !prevOpen)
  }

  const handleCloseHoodDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setNeighborhoodsDropdownOpen(false)
  }

  const handleNeighborhoodSelection = (event, neighborhood) => {
    setSelectedNeighborhood(neighborhood)
    setNeighborhoodsDropdownOpen(false)
    setSelectedInputMethod(true)
  }

  const percentageFlag = (key) =>
    key === "interestRate" ||
    key === "downPaymentPercentage" ||
    key === "termInYears"

  const parseValue = (value) => {
    const numericValue = value.replace(/[^\d.-]/g, "")
    return numericValue ? parseFloat(numericValue) : null
  }

  const formatValue = (value, key) => {
    if (value === null) return ""
    const numericValue = parseFloat(value)
    if (percentageFlag(key)) {
      return isNaN(numericValue) ? "" : `${numericValue}`
    } else {
      return isNaN(numericValue) ? "" : `$${numericValue.toLocaleString()}`
    }
  }

  const handleInputChange = useCallback((key, value, expenses) => {
    const numericValue = parseValue(value) // Parse the input value to a number
    expenses
      ? setExpensesObject((prevState) => ({
          ...prevState,
          [key]: numericValue,
        }))
      : setIncomeObject((prevState) => ({
          ...prevState,
          [key]: numericValue,
        }))
  }, [])

  const handleButtonClick = (key) => {
    // Set all keys to false, except the one that was clicked
    const updatedState = Object.keys(showGraph).reduce((state, currentKey) => {
      state[currentKey] = currentKey === key
      return state
    }, {})

    setShowGraph(updatedState)
  }

  const handleShowMspChart = () => {
    switch (true) {
      case showGraph.house:
        return selectedNeighborhood.detachedPrice
      case showGraph.condo:
        return selectedNeighborhood.condoPrice
      case showGraph.multiFamily:
        return selectedNeighborhood.mfPrice
      default:
        return selectedNeighborhood.mfPrice
    }
  }
  const handleShowSupplyChart = () => {
    switch (true) {
      case showGraph.house:
        return selectedNeighborhood.detachedSupply
      case showGraph.condo:
        return selectedNeighborhood.condoSupply
      case showGraph.multiFamily:
        return selectedNeighborhood.mfSupply
      default:
        return selectedNeighborhood.mfSupply
    }
  }

  const handleCustomInput = () => {
    setSelectedInputMethod(true)
    setShowGraph({
      house: null,
      condo: null,
      multiFamily: null,
    })
    setSelectedNeighborhood(null)
  }
  const handleBedroomSelection = (key, bedroomOption) => {
    if (selectedNeighborhood) {
      const optionKey = bedroomOption.split(" ")[0].toLowerCase() + "Bed" // Maps "2 beds" to "twoBed"
      const rentValue = selectedNeighborhood[optionKey]
      if (rentValue !== undefined) {
        setIncomeObject((prevState) => ({
          ...prevState,
          [key]: rentValue,
        }))
      }
    }
  }

  const dpDecimal = parseFloat(expensesObject.downPaymentPercentage / 100)
  const monthlyPropTax = expensesObject.annualTax / 12
  const getTotalIncome = () => {
    return Object.values(incomeObject).reduce((total, value) => {
      return total + value
    }, 0)
  }
  const totalIncome = getTotalIncome()
  const compute = (e) => {
    let downPaymentVar = 0
    let closingCosts = expensesObject.purchasePrice * 0.02
    let monthlyPmtVar = 0
    let loanAmountVar = 0
    downPaymentVar = expensesObject.purchasePrice * dpDecimal
    loanAmountVar = expensesObject.purchasePrice - downPaymentVar
    monthlyPmtVar = mortgageHelpers.getMonthlyPayments(
      loanAmountVar,
      parseFloat(expensesObject.interestRate),
      expensesObject.termInYears * 12
    )
    // : 0
    let monthlyPaymentVar = 0
    const monthlyPay =
      monthlyPmtVar +
      monthlyPropTax +
      parseInt(expensesObject.monthlyUtilities) +
      parseInt(expensesObject.monthlyInsurance) +
      parseInt(expensesObject.miscExpenses)
    // const paidInFullPmt =
    //   monthlyPropTax +
    //   parseInt(calculatorObject.monthlyUtilities) +
    //   parseInt(calculatorObject.monthlyInsurance) +
    //   parseInt(calculatorObject.miscExpenses)
    let flagPMI = null
    if (dpDecimal < 0.2) {
      monthlyPaymentVar = monthlyPay + 300
      flagPMI = true
    }
    // else if (dpDecimal > 0.25) {
    //   monthlyPaymentVar = paidInFullPmt
    //   setPmiIncluded("")
    // }
    else {
      monthlyPaymentVar = monthlyPay
      flagPMI = false
      // setPmiIncluded("")
    }
    const vacancyRate = totalIncome * 0.05
    const capEx = totalIncome * 0.05
    const monthlyNetIncomeVar = totalIncome - monthlyPaymentVar
    const capRateNoi =
      totalIncome -
      expensesObject.monthlyUtilities -
      expensesObject.monthlyInsurance -
      expensesObject.miscExpenses
    const cocAnnualNoi = (monthlyNetIncomeVar - vacancyRate - capEx) * 12

    setResults({
      ...results,
      downPaymentAmount: downPaymentVar,
      pmiIncluded: flagPMI ? " & $300 of PMI" : "",
      leftOverMoney:
        expensesObject.moneyOnHand !== null
          ? expensesObject.moneyOnHand - downPaymentVar
          : 0,
      monthlyPayment: monthlyPaymentVar,
      monthlyGross: totalIncome,
      yearlyGross: totalIncome * 12,
      monthlyIncome: monthlyNetIncomeVar,
      yearlyIncome: monthlyNetIncomeVar * 12,
      capRate: ((capRateNoi * 12) / expensesObject.purchasePrice) * 100,
      cashOnCash: (cocAnnualNoi / (downPaymentVar + closingCosts)) * 100,
    })
  }
  function formatNumber(num) {
    const fixedNum = num.toFixed(0)
    // Convert the fixed string back to a number, then format it with locale string
    const formattedNumber = parseFloat(fixedNum).toLocaleString()
    return formattedNumber
  }

  const addBuildingToUser = async (userId, buildingData) => {
    console.log("BUILDING DATA: ", buildingData)
    try {
      await axios.post(
        `https://levis-roi-app.onrender.com/users/add-building/${userId}`,
        buildingData
      )
      console.log("Building added")
    } catch (error) {
      console.error("Error adding building:", error)
    }
  }
  const fetchAndDisplayBuildings = async (userId) => {
    try {
      console.log("fetching..")
      const response = await axios.get(
        `https://levis-roi-app.onrender.com/users/buildings/${userId}`
      )
      // const buildings = response.data
      console.log("FETCH IS WORKING", response.data)
      setBuildingsList(response.data)
      // Now you have the buildings, you can display them
    } catch (error) {
      console.error("Error fetching buildings:", error)
    }
  }
  const deleteBuilding = async (userId, buildingName) => {
    try {
      await axios.delete(
        `https://levis-roi-app.onrender.com/users/remove-building/${userId}`,
        { data: { buildingName } }
      )
      console.log("Building removed")
      // Optionally, refresh the list of buildings for the UI here
    } catch (error) {
      console.error("Error removing building:", error)
    }
  }

  // Create a ref for the element to scroll to
  const scrollRef = useRef(null)
  const scrollNeighborhoodRef = useRef(null)

  // Function to scroll to the ref
  const scrollToRef = () =>
    scrollRef.current.scrollIntoView({ behavior: "smooth" })
  const scrollToAccordionsRef = () => {
    setTimeout(() => {
      window.scrollBy({
        top: 800, // Vertical scroll amount in pixels
        behavior: "smooth", // Optional: smooth scroll
      })
    }, 200)
  }
  const scrollToNeighborhoodRef = () =>
    scrollNeighborhoodRef.current.scrollIntoView({ behavior: "smooth" })

  const pageDescription = () => {
    return (
      <>
        <Typography color={"white"}>
          Select Niche for a specific Chicago neighborhood to utilize the data
          for the average rent amount (rounded to the nearest $25) in that
          specific neighborhood. Get hyper-local data with graphs that encompass
          the neighborhood's micro-market. All auto-populated rent amounts are
          for units with 1, 2, 3 bedrooms and 1 bath.
        </Typography>
        <Typography color={"white"} ref={scrollRef}>
          Select Custom for a quicker, custom experience. No data, no graphs,
          just some quick napkin math without the napkin.
        </Typography>
      </>
    )
  }
  return (
    <>
      <CalculatorTemplate
        pageTitle={"Mortgage Calculator"}
        pageDescriptionTitle={"How it works"}
        descriptionChildren={pageDescription()}
      >
        <ButtonGroup
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: selectedInputMethod ? null : "200px",
          }}
          ref={anchorRef}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCustomInput()}
          >
            Custom
          </Button>
          <Button
            variant="contained"
            color="warning"
            endIcon={<ArrowDropDownIcon />}
            onClick={handleToggleHoodDropdown}
          >
            Niche
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 10,
          }}
          open={neighborhoodsDropdownOpen}
          anchorEl={anchorRef.current}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                // marginLeft: "130px",
              }}
            >
              <Paper
                data-test-id="neighborhood_dropdown"
                sx={{ maxHeight: "400px" }}
              >
                <ClickAwayListener onClickAway={handleCloseHoodDropdown}>
                  <MenuList
                    sx={{ maxHeight: 400, overflow: "auto" }}
                    id="split-button-menu"
                    autoFocusItem={true}
                  >
                    {neighborhoods.map((neighborhood) => (
                      <MenuItem
                        data-test-id="option"
                        key={neighborhood.name}
                        selected={neighborhood.name === selectedNeighborhood}
                        onClick={(event) =>
                          handleNeighborhoodSelection(event, neighborhood)
                        }
                      >
                        {neighborhood.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {selectedInputMethod && (
          <>
            {selectedNeighborhood && (
              <>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                    width={"100%"}
                    src={neighborhoodPic}
                    alt="neighborhood outline"
                  />
                  <Typography
                    color={"white"}
                    sx={{ textAlign: "center", fontSize: 45, fontWeight: 600 }}
                  >
                    {selectedNeighborhood.name}
                  </Typography>
                </Box>

                <ButtonGroup
                  sx={{
                    transform: "scale(1.1)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {Object.keys(showGraph).map((key) => (
                    <Button
                      key={key}
                      variant="contained"
                      onClick={() => handleButtonClick(key)}
                    >
                      {toSentenceCase(key)}
                    </Button>
                  ))}
                </ButtonGroup>

                {showGraph.condo || showGraph.house || showGraph.multiFamily ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobileScreen ? "column" : "row",
                      gap: isMobileScreen ? 5 : 20,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <iframe
                      title="Chicago10yearSupply"
                      width="329"
                      height="300"
                      src={`${handleShowSupplyChart()}325&h=300`}
                    />
                    <iframe
                      title="chicago10yearMSP"
                      width="329"
                      height="300"
                      src={`${handleShowMspChart()}325&h=300`}
                      ref={scrollNeighborhoodRef}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      transform: "scale(1.2)",
                    }}
                    ref={scrollNeighborhoodRef}
                  >
                    <TouchAppIcon sx={{ color: "white" }} />
                    <Typography color={"white"}>
                      Select a home type to display metrics
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <CalculatorFormBox>
              {Object.keys(expensesObject).map((key) => (
                <>
                  <TextField
                    required={key !== "moneyOnHand" && true}
                    fullWidth
                    key={key}
                    type={percentageFlag(key) ? "number" : "text"}
                    value={formatValue(expensesObject[key], key)}
                    onChange={(e) =>
                      handleInputChange(key, e.target.value, true)
                    }
                    label={toSentenceCase(key)}
                    sx={textFieldStyles}
                    InputProps={{
                      endAdornment:
                        percentageFlag(key) && key !== "termInYears" ? (
                          <Typography color={"white"}>%</Typography>
                        ) : null,
                    }}
                  />
                  {key === "interestRate" && (
                    <Typography color={"white"} textAlign={"center"}>
                      The numbers below are preset. You can adjust them as
                      needed
                    </Typography>
                  )}
                </>
              ))}
              <ButtonGroup>
                <Button
                  color="info"
                  size="small"
                  variant="contained"
                  startIcon={<AddHomeIcon />}
                  onClick={() => addUnit()}
                >
                  Add Unit
                </Button>
                <Button
                  color="error"
                  size="small"
                  variant="contained"
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => deleteUnit()}
                >
                  Delete unit
                </Button>
              </ButtonGroup>
              {Object.keys(incomeObject).map((key) => (
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  {/* RENT INCOME */}
                  <TextField
                    inputRef={refsMap[key]} // Use the ref from refsMap
                    required={
                      key !== "parkingIncome" && key !== "laundryIncome"
                    }
                    fullWidth={
                      key === "parkingIncome" || key === "laundryIncome"
                    }
                    label={
                      key !== "parkingIncome" && key !== "laundryIncome"
                        ? `${toSentenceCase(key)} Rent`
                        : toSentenceCase(key)
                    }
                    sx={[
                      textFieldStyles,
                      {
                        width:
                          key !== "parkingIncome" && key !== "laundryIncome"
                            ? "50%"
                            : null,
                      },
                    ]}
                    type={percentageFlag(key) ? "number" : "text"}
                    value={formatValue(incomeObject[key], key)}
                    onChange={(e) =>
                      handleInputChange(key, e.target.value, false)
                    }
                  />
                  {key !== "parkingIncome" && key !== "laundryIncome" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      {/* NUMBER OF BEDROOMS */}
                      <TextField
                        select
                        sx={selectFieldStyles}
                        label={
                          isMobileScreen
                            ? "Amt of Beds"
                            : `Amount of Beds for ${toSentenceCase(key)}`
                        }
                        helperText={"Only 1 bath"}
                        onFocus={() => {
                          document.body.style.paddingRight = "0px"
                        }}
                        onChange={(e) =>
                          handleBedroomSelection(key, e.target.value)
                        }
                      >
                        {bedroomOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}
                </Box>
              ))}
              {expensesObject.purchasePrice !== null &&
                expensesObject.annualTax !== null &&
                incomeObject.unit1 !== null && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={(e) => {
                      setDialogOpen(true)
                      compute()
                      formCtx.addMortgageFormData({
                        ...expensesObject,
                        ...results,
                      })
                    }}
                    sx={{ position: "sticky", bottom: "50px", zIndex: 2 }}
                  >
                    Compute
                  </Button>
                )}
            </CalculatorFormBox>
            {buildingsList && (
              <Button
                size="large"
                startIcon={<DomainAddIcon />}
                variant="contained"
                color="warning"
                onClick={() => {
                  setExpensesObject({
                    ...expensesObject,
                    purchasePrice: null,
                    annualTax: null,
                  })
                  setIncomeObject({
                    unit1: null,
                    parkingIncome: null,
                    laundryIncome: null,
                  })
                  if (selectedNeighborhood === null) {
                    return scrollToRef()
                  }

                  scrollToNeighborhoodRef()
                }}
              >
                Add Building/Reset Fields
              </Button>
            )}
          </>
        )}
        <Box>
          {userId && buildingsList ? (
            buildingsList.map((building) => (
              <Accordion key={building.name}>
                <AccordionSummary
                  onClick={() => {
                    scrollToAccordionsRef()
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.5rem",

                      color: "black",
                    }}
                  >
                    {building.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box sx={resultsStyles}>
                      <Typography fontWeight={600}>Down Payment:</Typography> $
                      {formatNumber(building.downPaymentAmount)}
                    </Box>
                    {building.moneyOnHand && building.leftOverMoney && (
                      <Box sx={resultsStyles}>
                        <Typography>Your left over money is </Typography>
                        {formatNumber(building.leftOverMoney)}
                      </Box>
                    )}
                    <Box sx={resultsStyles}>
                      <Typography>
                        <strong>Estimated Monthly Payment</strong> (with
                        utilities
                        {building.pmiIncluded}):{" "}
                      </Typography>{" "}
                      ${formatNumber(building.monthlyPayment)}
                    </Box>
                    <Box sx={resultsStyles}>
                      <Typography>Monthly Gross Income: </Typography>$
                      {formatNumber(building.monthlyGross)}
                    </Box>
                    <Box sx={resultsStyles}>
                      <Typography>Yearly Gross Income: </Typography>$
                      {formatNumber(building.yearlyGross)}
                    </Box>
                    <Box sx={{ paddingTop: "2rem" }}>
                      <Box sx={[resultsStyles, { border: "none" }]}>
                        <Typography variant="h6">
                          Potential Monthly NET{" "}
                          {building.monthlyIncome >= 0 ? "income:" : "payment:"}{" "}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            color: building.monthlyIncome < 1 ? "red" : "black",
                            fontWeight: 600,
                          }}
                        >
                          ${formatNumber(building.monthlyIncome)}
                        </Typography>
                      </Box>
                      <Box sx={[resultsStyles, { border: "none" }]}>
                        <Typography>Potential Yearly NET Income: </Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {formatNumber(building.yearlyIncome)}
                        </Typography>
                      </Box>
                    </Box>
                    {building.monthlyIncome > 1 && (
                      <Typography variant="caption">
                        <em>
                          That's a {building.capRate.toFixed(2)}%{" "}
                          <strong>Cap-Rate</strong> and{" "}
                          {building.cashOnCash.toFixed(2)}%{" "}
                          <strong>Cash-On-Cash</strong>
                        </em>
                      </Typography>
                    )}
                    <Box
                      sx={{
                        marginTop: "40px",
                        borderTop: "1px solid rgba(0, 0, 0, 0.15)",
                        textAlign: "center",
                      }}
                    >
                      {building.expensesObject && (
                        <Typography>
                          Interest rate was set at{" "}
                          <span style={{ fontWeight: 900 }}>
                            {building.expensesObject.interestRate}%
                          </span>
                        </Typography>
                      )}
                      {Object.entries(building.incomeObject).map(
                        ([key, value]) => {
                          if (value === null) {
                            return null
                          }
                          if (key.startsWith("unit")) {
                            const unitNumber = key.replace("unit", "Unit ")
                            return (
                              <Typography key={key + value}>
                                {unitNumber} rent was ${value.toLocaleString()}
                              </Typography>
                            )
                          } else {
                            return (
                              value !== null && (
                                <Typography key={key + value}>
                                  {toSentenceCase(key)} was $
                                  {value.toLocaleString()}
                                </Typography>
                              )
                            )
                          }
                        }
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
                <AccordionActions>
                  {building.expensesObject && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        handleCustomInput()
                        await getBuildingData(building.name)
                        if (selectedNeighborhood === null) {
                          return scrollToRef()
                        }

                        scrollToNeighborhoodRef()
                      }}
                    >
                      Revisit
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={async () => {
                      await deleteBuilding(userId, building.name)
                      // setTimeout(() => {
                      await fetchAndDisplayBuildings(userId)
                      // }, 300)
                    }}
                  >
                    Delete
                  </Button>
                </AccordionActions>
              </Accordion>
            ))
          ) : userId && buildingsList === null ? (
            <Button
              color="warning"
              variant="contained"
              onClick={() => fetchAndDisplayBuildings(userId)}
            >
              Get My Saved Buildings
            </Button>
          ) : null}
        </Box>
        <Footer />
      </CalculatorTemplate>
      <Dialog
        disableScrollLock={true}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      >
        <DialogTitle>Here are the numbers...</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box sx={resultsStyles}>
            <Typography fontWeight={600}>Down Payment:</Typography> $
            {formatNumber(results.downPaymentAmount)}
          </Box>
          {expensesObject.moneyOnHand && results.leftOverMoney && (
            <Box sx={resultsStyles}>
              <Typography>Your left over money is </Typography>
              {formatNumber(results.leftOverMoney)}
            </Box>
          )}
          <Box sx={resultsStyles}>
            <Typography>
              <strong>Estimated Monthly Payment</strong> (with utilities
              {results.pmiIncluded}):{" "}
            </Typography>{" "}
            ${formatNumber(results.monthlyPayment)}
          </Box>
          <Box sx={resultsStyles}>
            <Typography>Monthly Gross Income: </Typography>$
            {formatNumber(results.monthlyGross)}
          </Box>
          <Box sx={resultsStyles}>
            <Typography>Yearly Gross Income: </Typography>$
            {formatNumber(results.yearlyGross)}
          </Box>
          <Box sx={{ paddingTop: "2rem" }}>
            <Box sx={[resultsStyles, { border: "none" }]}>
              <Typography variant="h6">
                Potential Monthly NET{" "}
                {results.monthlyIncome >= 0 ? "income:" : "payment:"}{" "}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: results.monthlyIncome < 1 ? "red" : "black",
                  fontWeight: 600,
                }}
              >
                ${formatNumber(results.monthlyIncome)}
              </Typography>
            </Box>
            <Box sx={[resultsStyles, { border: "none" }]}>
              <Typography>Potential Yearly NET Income: </Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {formatNumber(results.yearlyIncome)}
              </Typography>
            </Box>
          </Box>
          {results.monthlyIncome > 1 && (
            <Typography variant="caption">
              <em>
                That's a {results.capRate.toFixed(2)}% <strong>Cap-Rate</strong>{" "}
                and {results.cashOnCash.toFixed(2)}%{" "}
                <strong>Cash-On-Cash</strong>
              </em>
            </Typography>
          )}
          {results.leftOverMoney < 0 && (
            <Link to={"/savings-calculator"}>
              <Button variant="contained" color="warning">
                Figure out how to save
              </Button>
            </Link>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <TextField
            label={"Building Name"}
            onChange={(e) => setBuildingName(e.target.value)}
          ></TextField>
          <Button
            variant="contained"
            disabled={!buildingName}
            onClick={async () => {
              checkStorage("levis-user-id")
              setDialogOpen(false)
              console.log("before ADD")
              await addBuildingToUser(userId, {
                name: buildingName,
                ...results,
                incomeObject: incomeObject,
                expensesObject: expensesObject,
              })
              console.log("setting expenses OBJECT", expensesObject)
              console.log("before fetch")
              await fetchAndDisplayBuildings(userId)
              console.log("after fetch")

              setTimeout(() => {
                window.scrollTo({
                  top: document.body.scrollHeight + 300,
                  behavior: "smooth", // This enables smooth scrolling. Remove this line if you want instant scrolling.
                })
              }, 600)
              setBuildingName(null)
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCreateUserModel}
        onClose={() => setOpenCreateUserModel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4">Looks like you're new!</Typography>
        </DialogTitle>
        <DialogContent>
          <CreateUser handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MortgageCalculator
