import React, { useContext, useState } from "react"
import FormContext from "../contexts/form-context"
import CalculatorTemplate from "../components/CalculatorTemplate"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import CalculatorFormBox from "../components/CalculatorFormBox"
import { textFieldStyles, resultsStyles } from "./styles.css"
import { Close } from "@mui/icons-material"
import Footer from "../components/Footer"

const SavingsCalculator = () => {
  const formCtx = useContext(FormContext)
  const [downPaymentAmount, setDownPaymentAmount] = useState(
    formCtx.mortgageFormData?.downPaymentAmount ?? 0
  )
  const [numberOfMonthsToSave, setNumberOfMonthsToSave] = useState(0)
  const [monthsToYears, setMonthsToYears] = useState(0)
  const [calculatorObject, setCalculatorObject] = useState({
    moneyOnHand: formCtx.mortgageFormData?.moneyOnHand ?? 0,
    purchasePrice: formCtx.mortgageFormData?.purchasePrice ?? 0,
    downPaymentPercentage:
      formCtx.mortgageFormData?.downPaymentPercentage ?? "3.5",
    savedPerMonth: 0,
  })
  const [open, setOpen] = useState(false)

  const percentageFlag = (key) =>
    key === "interestRate" || key === "downPaymentPercentage"

  const parseValue = (value) => {
    const numericValue = value.replace(/[^\d.-]/g, "")
    return numericValue ? parseFloat(numericValue) : null
  }

  const formatValue = (value, key) => {
    if (value === null) return ""
    const numericValue = parseFloat(value)
    if (percentageFlag(key)) {
      return isNaN(numericValue) ? "" : `${numericValue}`
    } else {
      return isNaN(numericValue) ? "" : `$${numericValue.toLocaleString()}`
    }
  }

  const handleInputChange = (key, value) => {
    const numericValue = parseValue(value) // Parse the input value to a number
    setCalculatorObject((prevState) => ({
      ...prevState,
      [key]: numericValue,
    }))
  }
  const toSentenceCase = (str) => {
    // Split by camelCase or numbers
    const parts = str.split(/(?=[A-Z0-9])/)
    // Capitalize each part and join with space
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ")
  }
  function formatNumber(num, fixed) {
    if (!num) {
      return
    }
    const fixedNum = num.toFixed(fixed)
    // Convert the fixed string back to a number, then format it with locale string
    const formattedNumber = parseFloat(fixedNum).toLocaleString()
    return formattedNumber
  }

  const dpDecimal = parseFloat(calculatorObject.downPaymentPercentage / 100)
  const compute = (e) => {
    let downPaymentVar = 0
    dpDecimal <= 0.25
      ? (downPaymentVar = calculatorObject.purchasePrice * dpDecimal)
      : (downPaymentVar = calculatorObject.purchasePrice)
    setDownPaymentAmount(downPaymentVar)
    const diffInMoneyNeeded = downPaymentVar - calculatorObject.moneyOnHand
    const saveInMonths = diffInMoneyNeeded / calculatorObject.savedPerMonth

    setNumberOfMonthsToSave(saveInMonths < 0 ? 0 : saveInMonths)
    setMonthsToYears(saveInMonths < 0 ? 0 : saveInMonths / 12)
  }

  const pageDescription = () => {
    return (
      <>
        <Typography color={"white"}>
          STOP using phrases like, "Maybe next year," or "Hopefully in a few
          months," or "That will take forever to save for!" With the savings
          calculator you can determine exactly how long it will take for you to
          save for your first (or next) investment opportunity.
        </Typography>
        <Typography color={"white"}>
          If you're coming from the Mortgage Calculator, some values have been
          pre-populated for you using your most recent building input. Decide
          exactly how much you will save every month. If you stick to your plan,
          then this will tell you <strong>EXACTLY</strong> how long it will take
          for you to save
        </Typography>
      </>
    )
  }
  return (
    <>
      <CalculatorTemplate
        pageTitle={"Savings Calculator"}
        pageDescriptionTitle={"How it works"}
        descriptionChildren={pageDescription()}
      >
        <CalculatorFormBox>
          {Object.keys(calculatorObject).map((key) => (
            <TextField
              required
              fullWidth
              key={key}
              type={percentageFlag(key) ? "number" : "text"}
              value={formatValue(calculatorObject[key], key)}
              onChange={(e) => handleInputChange(key, e.target.value, true)}
              label={toSentenceCase(key)}
              sx={textFieldStyles}
              InputProps={{
                endAdornment: percentageFlag(key) ? (
                  <Typography color={"white"}>%</Typography>
                ) : null,
              }}
            />
          ))}
          {Object.values(calculatorObject).every(
            (value) => value !== null && calculatorObject.savedPerMonth !== 0
          ) && (
            <Button
              variant="contained"
              color="success"
              sx={{ width: "50%", marginX: "auto" }}
              onClick={() => {
                setOpen(true)
                compute()
              }}
            >
              Compute
            </Button>
          )}
        </CalculatorFormBox>
        <Footer />
      </CalculatorTemplate>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Here are the numbers...</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box sx={resultsStyles}>
            <Typography>Money on hand:</Typography> $
            {formatNumber(calculatorObject.moneyOnHand, 0)}
          </Box>
          <Box sx={resultsStyles}>
            <Typography>Down Payment:</Typography> $
            {formatNumber(downPaymentAmount, 0)}
          </Box>
          <Box sx={resultsStyles}>
            <Typography>Saved per month</Typography> $
            {formatNumber(calculatorObject.savedPerMonth, 0)}
          </Box>
          {/* Bottom-line RESULTS */}
          <Box sx={{ paddingTop: "2rem" }}>
            <Box sx={[resultsStyles, { border: "none" }]}>
              {numberOfMonthsToSave <= 0 ? (
                <Typography>GO BUY IT!</Typography>
              ) : (
                <Typography variant="h6">
                  Save for{" "}
                  <strong>
                    {formatNumber(numberOfMonthsToSave, 2)}{" "}
                    {numberOfMonthsToSave === 1 ? "month" : "months"}
                  </strong>
                </Typography>
              )}
            </Box>

            <Box sx={[resultsStyles, { border: "none" }]}>
              {monthsToYears !== 0 && (
                <Typography>
                  That's {formatNumber(monthsToYears, 2)}{" "}
                  {monthsToYears === 1 ? "year" : "years"}
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SavingsCalculator
