import { Box } from "@mui/material"
import React from "react"
import { formStyles } from "../pages/styles.css"

const CalculatorFormBox = ({ children }) => {
  return (
    <Box component={"form"} sx={formStyles}>
      {children}
    </Box>
  )
}

export default CalculatorFormBox
