import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  useTheme,
  useMediaQuery,
  Fab,
} from "@mui/material"
import React from "react"
import ReactGa from "react-ga"
import CalculateIcon from "@mui/icons-material/Calculate"
import { Link } from "react-router-dom"
import marketTrendsGraphs from "./marketTrendsGraphs.json"
import Footer from "../components/Footer"

const MarketTrends = () => {
  const [showGraph, setShowGraph] = React.useState({
    overall: true,
    houses: false,
    condos: false,
    multiFamilies: false,
  })
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))

  const ShowGraph = () => {
    const trueKey = Object.keys(showGraph).find(
      (key) => showGraph[key] === true
    )
    const matchingObject = marketTrendsGraphs.find(
      (obj) => obj.name === trueKey
    )
    return (
      <Box>
        <Typography variant="h4" component={"p"} color={"white"}>
          Monthly Supply:
        </Typography>
        <iframe
          title="Chicago10yearSupply"
          width={isLargeScreen ? "700" : "329"}
          height={isLargeScreen ? "400" : "300"}
          src={`${matchingObject.supplyPath}${
            isLargeScreen ? "696" : "325"
          } &h=${isLargeScreen ? "400" : "300"}`}
        />
        <Typography variant="h4" component={"p"} color={"white"}>
          Median Sales Price:
        </Typography>
        <iframe
          title="chicago10yearMSP"
          width={isLargeScreen ? "700" : "329"}
          height={isLargeScreen ? "400" : "300"}
          src={`${matchingObject.mspPath}${isLargeScreen ? "696" : "325"} &h=${
            isLargeScreen ? "400" : "300"
          }`}
        ></iframe>
      </Box>
    )
  }
  //ReactGA button click handler
  const showTrendsHandler = (propertyType) => {
    ReactGa.event({
      category: "Button",
      action: `Show ${propertyType} Market Trends`,
    })
    console.log("propertyType", propertyType)
    setShowGraph((prevState) => {
      const updatedState = {
        overall: false,
        houses: false,
        condos: false,
        multiFamilies: false,
      }
      if (updatedState.hasOwnProperty(propertyType)) {
        updatedState[propertyType] = true
      }
      return updatedState
    })
  }
  const buttonStyle = {
    color: "white",
    borderColor: "white",
    ":hover": { border: "5px solid white" },
    minHeight: "75px",
  }
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom right, black, #B03A2E)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            color: "white",
            fontSize: "2.5rem",
            fontWeight: 600,
          }}
        >
          Market Trends In Chicago
        </Typography>
        <ButtonGroup
          sx={{ display: "flex", justifyContent: "center", marginY: "24px" }}
          variant="outlined"
          aria-label="Basic button group"
          size="large"
        >
          <Button
            sx={[buttonStyle, { borderRadius: "25px" }]}
            onClick={() => showTrendsHandler("overall")}
          >
            Overall
          </Button>
          <Button sx={buttonStyle} onClick={() => showTrendsHandler("condos")}>
            Condos
          </Button>
          <Button sx={buttonStyle} onClick={() => showTrendsHandler("houses")}>
            Houses
          </Button>
          <Button
            sx={[buttonStyle, { borderRadius: "25px" }]}
            onClick={() => showTrendsHandler("multiFamilies")}
          >
            Mutli-Families
          </Button>
        </ButtonGroup>
        <Box sx={{ textAlign: "center" }}>
          <ShowGraph />
        </Box>
        <Link to={"/calculator-list"}>
          <Fab
            sx={{
              position: "sticky",
              bottom: "70px",
              left: 0,
              right: 0,
              margin: "0 auto",
              display: "flex",
              transform: "scale(2)",
              // backgroundColor: "#A1A1A1",
              border: '2px solid black'
            }}
          >
            <CalculateIcon />
          </Fab>
        </Link>
      </Box>
      <Footer />
    </Box>
  )
}

export default MarketTrends
