import React from "react"
import CalculateIcon from "@mui/icons-material/Calculate"
import QueryStatsIcon from "@mui/icons-material/QueryStats"
import InfoIcon from "@mui/icons-material/Info"
import EventIcon from "@mui/icons-material/Event"
import { Link } from "react-router-dom"
import { Box, Fab, Tooltip } from "@mui/material"

const DesktopQuickNav = () => {
  return (
    <Box sx={{ display: "flex", gap: "15vw" }}>
      <Link to="/calculator-list">
        <Tooltip title="Work the numbers" placement="top">
          <Fab size="large" sx={{ transform: "scale(3)" }}>
            <CalculateIcon />
          </Fab>
        </Tooltip>
      </Link>
      <Link to="/about">
        <Tooltip title="About" placement="top">
          <Fab size="large" sx={{ transform: "scale(3)" }}>
            <InfoIcon />
          </Fab>
        </Tooltip>
      </Link>
      <Link to="/book-an-appointment">
        <Tooltip title="Book A Free Consult" placement="top">
          <Fab size="large" sx={{ transform: "scale(3)" }}>
            <EventIcon />
          </Fab>
        </Tooltip>
      </Link>
      <Link to="/market-trends">
        <Tooltip title="See the Stats" placement="top">
          <Fab sx={{ transform: "scale(3)" }}>
            <QueryStatsIcon />
          </Fab>
        </Tooltip>
      </Link>
    </Box>
  )
}

export default DesktopQuickNav
