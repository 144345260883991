import {
  Box,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material"
import React, { useState } from "react"
import { textFieldStyles } from "./styles.css"
import mortgageHelpers from "mortgage-helpers"
import { Close } from "@mui/icons-material"
import CalculatorTemplate from "../components/CalculatorTemplate"
import CalculatorFormBox from "../components/CalculatorFormBox"
import Footer from "../components/Footer"

const SelfSufficiency = () => {
  const [open, setOpen] = React.useState(false)
  const [selfSufficient, setSelfSufficient] = useState(false)
  const [selfSufficientRate, setSelfSufficientRate] = useState(0)
  const [calculatorObject, setCalculatorObject] = useState({
    purchasePrice: null,
    downPaymentPercentage: null,
    interestRate: null,
    totalIncome: null,
    yearlyTaxes: null,
    monthlyInsurance: null,
  })

  const percentageFlag = (key) =>
    key === "interestRate" || key === "downPaymentPercentage"

  // Parses a formatted string and returns a raw number
  const parseValue = (value) => {
    // Remove everything except digits, decimal points, and minus sign
    const numericValue = value.replace(/[^\d.-]/g, "")
    return numericValue ? parseFloat(numericValue) : null
  }

  // Formats a number into a string with commas
  const formatValue = (value, key) => {
    if (value === null) return ""
    // Convert to number to ensure we don't double-format
    const numericValue = parseFloat(value)
    // Check if the key is 'interestRate'
    if (percentageFlag(key)) {
      // If it is, return the numeric value directly
      return isNaN(numericValue) ? "" : `${numericValue}`
    } else {
      // If it's not 'interestRate', apply toLocaleString()
      return isNaN(numericValue) ? "" : `$${numericValue.toLocaleString()}`
    }
  }

  const handleInputChange = (key, value) => {
    const numericValue = parseValue(value) // Parse the input value to a number
    setCalculatorObject((prevState) => ({
      ...prevState,
      [key]: numericValue,
    }))
  }

  const dpDecimal = calculatorObject.downPaymentPercentage / 100
  const compute = (e) => {
    let monthlyPmtVar = 0
    let loanAmountVar = 0
    let downPaymentVar = 0
    let netMonthlyIncome = 0
    const monthlyPropTax = calculatorObject.yearlyTaxes / 12

    downPaymentVar = calculatorObject.purchasePrice * dpDecimal
    loanAmountVar = calculatorObject.purchasePrice - downPaymentVar
    monthlyPmtVar = mortgageHelpers.getMonthlyPayments(
      loanAmountVar,
      parseFloat(calculatorObject.interestRate),
      360
    )
    let totalMonthlyPaymentVar = 0
    const monthlyPay =
      monthlyPmtVar + monthlyPropTax + calculatorObject.monthlyInsurance
    totalMonthlyPaymentVar = monthlyPay + 300

    const vacancyRate = calculatorObject.totalIncome * 0.25
    netMonthlyIncome = calculatorObject.totalIncome - vacancyRate
    const selfSufficientCalc =
      parseInt(totalMonthlyPaymentVar) / netMonthlyIncome
    selfSufficientCalc < 1 ? setSelfSufficient(true) : setSelfSufficient(false)
    setSelfSufficientRate(selfSufficientCalc)
  }
  const selfSufficientRateToPercent = (rateAsDecimal) => {
    const decimalNumber = rateAsDecimal * 100
    return decimalNumber.toFixed(2)
  }

  const pageDescription = () => {
    return (
      <>
        <Typography color={"white"}>
          FHA's self-sufficiency test requires that the rental income from a
          3-or-4 unit building covers the property's expenses, including the
          housing payment. This test ensures that the property generates enough
          rent to be a sound investment and that you can make the mortgage
          payments and other obligations. In order to be considered{" "}
          <strong>
            <i>Self-Sufficient</i>
          </strong>{" "}
          the Self-sufficiency rate must{" "}
          <strong
            style={{
              backgroundColor: "rgb(255, 170, 0)",
              padding: 4,
              borderRadius: "5px",
              color: "black",
            }}
          >
            NOT exceed 100%
          </strong>
        </Typography>
        <Typography
          style={{
            border: "1px solid white",
            borderRadius: "25px",
            padding: "15px",
            color: "white",
          }}
        >
          <strong>Note: </strong>The total income is calculated by the banks
          from
          <strong> CONFORMING UNITS ONLY.</strong> They will also take into
          consideration the unit that you will occupy. this means that there
          will not be any income coming from the unit that you occupy.
        </Typography>
      </>
    )
  }

  return (
    <>
      <CalculatorTemplate
        pageTitle={"Self Sufficiency"}
        pageDescriptionTitle={"What is it?"}
        descriptionChildren={pageDescription()}
      >
        <CalculatorFormBox>
          {Object.keys(calculatorObject).map((key) => (
            <TextField
              key={key}
              type={percentageFlag(key) ? "number" : "text"}
              required
              fullWidth
              label={
                key.charAt(0).toUpperCase() +
                key
                  .slice(1)
                  .replace(/([A-Z])/g, " $1")
                  .trim()
              } // Convert camelCase to Title Case
              value={formatValue(calculatorObject[key], key)} // Format the value for display
              onChange={(e) => handleInputChange(key, e.target.value)} // Handle input changes
              sx={textFieldStyles}
              InputProps={{
                endAdornment: percentageFlag(key) ? (
                  <Typography color={"white"}>%</Typography>
                ) : null,
              }}
            />
          ))}
          {Object.values(calculatorObject).every((value) => value !== null) && (
            <Button
              variant="contained"
              color="success"
              sx={{ width: "50%", marginX: "auto" }}
              onClick={() => {
                setOpen(true)
                compute()
              }}
            >
              Compute
            </Button>
          )}
        </CalculatorFormBox>
        <Footer />
      </CalculatorTemplate>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Here are the numbers...</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography sx={{ fontSize: "1rem" }} gutterBottom>
            With a Self Sufficiency Rate of{" "}
            {selfSufficientRateToPercent(selfSufficientRate)}%
          </Typography>
          <Typography sx={{ fontSize: "1rem" }} gutterBottom>
            This property is...
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography
              sx={{
                fontSize: "3rem",
                color: selfSufficient ? "green" : "red",
                justifyContent: "end",
                lineHeight: "1",
                fontWeight: 600,
              }}
            >
              {selfSufficient ? "Self Sufficient!" : "NOT"}
            </Typography>
            {!selfSufficient && (
              <Typography sx={{ justifyContent: "end", fontWeight: 600 }}>
                self sufficient
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SelfSufficiency
