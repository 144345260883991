import { Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"

const Footer = () => {
  return (
    <Link style={{ textDecoration: "none" }} to={"/book-an-appointment"}>
      <Typography
        sx={{
          paddingY: "20px",
          color: "white",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Contact the Number Wizard here: <AutoFixHighIcon />
      </Typography>
    </Link>
  )
}

export default Footer
