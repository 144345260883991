export const redBackground = {
  background: "linear-gradient(to bottom right, black, #B00000)",
  height: "100%",
}

export const textFieldStyles = {
  "& label": {
    color: "white", // Label color
  },
  "& label:hover": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  "& .MuiFormHelperText-root": {
    color: "white", // Helper text color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Border color
    },
    "&:hover fieldset": {
      borderColor: "white", // Border hover color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color when focused
    },
    "& input": {
      color: "white", // Input text color
    },
  },
}

export const selectFieldStyles = {
  "& .MuiInputLabel-root": {
    color: "white", // Label color
  },
  "& .MuiInputLabel-root:hover": {
    color: "white", // Label color
  },
  "& .MuiInputLabel.Mui-focused": {
    color: "white",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white",
  },
  "& .MuiInputBase-root": {
    color: "white", // Input text color
  },
  "& .MuiFormHelperText-root": {
    color: "white", // Helper text color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Outline color
    },
    "&:hover fieldset": {
      borderColor: "white", // Outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Outline color when focused
    },
    "& .MuiSelect-icon": {
      color: "white", // Styles the dropdown arrow
    },
  },
}

export const formStyles = {
  background: "rgba(0, 0, 0, 0.3)",
  border: "4px solid white",
  borderRadius: "20px",
  boxShadow: "2px 10px 15px rgba(0, 0, 0, 0.2)",
  padding: "20px",
  "& > :not(style)": { m: 1 },
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
  marginBottom: "50px",
}

export const resultsStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
}
