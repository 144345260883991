import { Card, CardContent, CardHeader } from "@mui/material"
import React from "react"

const PageDescriptionCard = (props) => {
  return (
    <Card
      sx={{
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "20px",
        border: "2px solid white",
        boxShadow:
          "2px 2px 5px white, -2px 2px 5px white, -2px -2px 5px white, 2px -2px 5px white",
      }}
    >
      <CardHeader
        title={props.title}
        sx={{ paddingBottom: 0 }}
        titleTypographyProps={{ color: "white", fontSize: "2rem" }}
      />
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
      >
        {props.children}
      </CardContent>
    </Card>
  )
}

export default PageDescriptionCard
