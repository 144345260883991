import React, { useState } from "react"
import CalculatorTemplate from "../components/CalculatorTemplate"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import CalculatorFormBox from "../components/CalculatorFormBox"
import { resultsStyles, textFieldStyles } from "./styles.css"
import mortgageHelpers from "mortgage-helpers"
import { Close } from "@mui/icons-material"
import Footer from "../components/Footer"

const AirBnbCalculator = () => {
  const [downPaymentAmount, setDownPaymentAmount] = useState(0)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [pmiIncluded, setPmiIncluded] = useState("")
  const [breakEven, setBreakEven] = useState(0)
  const [calculatorObject, setCalculatorObject] = useState({
    purchasePrice: null,
    annualTax: null,
    downPaymentPercentage: 20,
    interestRate: null,
    monthlyInsurance: 150,
    monthlyUtilities: 400,
    pricePerNight: null,
    minimumNights: null,
  })
  const [open, setOpen] = useState(false)

  const percentageFlag = (key) =>
    key === "interestRate" ||
    key === "downPaymentPercentage" ||
    key === "minimumNights"

  const parseValue = (value) => {
    const numericValue = value.replace(/[^\d.-]/g, "")
    return numericValue ? parseFloat(numericValue) : null
  }

  const formatValue = (value, key) => {
    if (value === null) return ""
    const numericValue = parseFloat(value)
    if (percentageFlag(key)) {
      return isNaN(numericValue) ? "" : `${numericValue}`
    } else {
      return isNaN(numericValue) ? "" : `$${numericValue.toLocaleString()}`
    }
  }

  const handleInputChange = (key, value) => {
    const numericValue = parseValue(value) // Parse the input value to a number
    setCalculatorObject((prevState) => ({
      ...prevState,
      [key]: numericValue,
    }))
  }
  const toSentenceCase = (str) => {
    // Split by camelCase or numbers
    const parts = str.split(/(?=[A-Z0-9])/)
    // Capitalize each part and join with space
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ")
  }
  function formatNumber(num, fixed) {
    if (!num) {
      return
    }
    const fixedNum = num.toFixed(fixed)
    // Convert the fixed string back to a number, then format it with locale string
    const formattedNumber = parseFloat(fixedNum).toLocaleString()
    return formattedNumber
  }

  const pageDescription = () => {
    return (
      <>
        <Typography color={"white"}>
          Why not have a vacation home that's paid for by somebody else?! Figure
          out <strong>EXACTLY</strong> how many reservations per year that
          you'll need in order to break even on your yearly expenses
        </Typography>
      </>
    )
  }
  const dpDecimal = parseFloat(calculatorObject.downPaymentPercentage / 100)
  const monthlyPropTax = calculatorObject.annualTax / 12

  const compute = (e) => {
    let downPaymentVar = 0
    let monthlyPmtVar = 0
    let loanAmountVar = 0
    dpDecimal <= 0.25
      ? (downPaymentVar = calculatorObject.purchasePrice * dpDecimal)
      : (downPaymentVar = calculatorObject.purchasePrice)
    loanAmountVar = calculatorObject.purchasePrice - downPaymentVar
    monthlyPmtVar =
      dpDecimal <= 0.25
        ? mortgageHelpers.getMonthlyPayments(
            loanAmountVar,
            parseFloat(calculatorObject.interestRate),
            360
          )
        : 0
    let monthlyPaymentVar = 0
    const monthlyPay =
      monthlyPmtVar +
      monthlyPropTax +
      parseInt(calculatorObject.monthlyUtilities) +
      parseInt(calculatorObject.monthlyInsurance)
    const paidInFullPmt =
      monthlyPropTax +
      parseInt(calculatorObject.monthlyUtilities) +
      parseInt(calculatorObject.monthlyInsurance)
    if (dpDecimal < 0.2) {
      monthlyPaymentVar = monthlyPay + 250
      setPmiIncluded(" & $300 of PMI")
    } else if (dpDecimal > 0.25) {
      monthlyPaymentVar = paidInFullPmt
      setPmiIncluded("")
    } else {
      monthlyPaymentVar = monthlyPay
      setPmiIncluded("")
    }
    // setMonthlyIncome(totalIncome - monthlyPaymentVar)
    setMonthlyPayment(monthlyPaymentVar)
    const yearlyPayments = monthlyPaymentVar * 12
    // setLoanAmount(loanAmountVar)
    setDownPaymentAmount(downPaymentVar)
    const incomePerReservation =
      calculatorObject.pricePerNight * calculatorObject.minimumNights
    setBreakEven(yearlyPayments / incomePerReservation)
  }
  return (
    <>
      <CalculatorTemplate
        pageTitle={"AirBnb Calculator"}
        pageDescriptionTitle={"How it works"}
        descriptionChildren={pageDescription()}
      >
        <CalculatorFormBox>
          {Object.keys(calculatorObject).map((key) => (
            <TextField
              required
              fullWidth
              key={key}
              type={percentageFlag(key) ? "number" : "text"}
              value={formatValue(calculatorObject[key], key)}
              onChange={(e) => handleInputChange(key, e.target.value, true)}
              label={toSentenceCase(key)}
              sx={textFieldStyles}
              InputProps={{
                endAdornment:
                  percentageFlag(key) && key !== "minimumNights" ? (
                    <Typography color={"white"}>%</Typography>
                  ) : null,
              }}
            />
          ))}
          {Object.values(calculatorObject).every(
            (value) => value !== null && calculatorObject.savedPerMonth !== 0
          ) && (
            <Button
              variant="contained"
              color="success"
              sx={{ width: "50%", marginX: "auto" }}
              onClick={() => {
                setOpen(true)
                compute()
              }}
            >
              Compute
            </Button>
          )}
        </CalculatorFormBox>
        <Footer />
      </CalculatorTemplate>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Here are the numbers...</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box sx={resultsStyles}>
            <Typography fontWeight={600}>Down Payment:</Typography> $
            {formatNumber(downPaymentAmount)}
          </Box>
          <Box sx={resultsStyles}>
            <Typography>
              <strong>Estimated Monthly Payment</strong> (with utilities
              {pmiIncluded}):{" "}
            </Typography>{" "}
            ${formatNumber(monthlyPayment)}
          </Box>
          <Box sx={{ paddingTop: "2rem" }}>
            <Box sx={[resultsStyles, { border: "none" }]}>
              <Typography variant="h6">
                You'll break even with{" "}
                <strong>{formatNumber(breakEven, 2)} </strong>
                reservations per year
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AirBnbCalculator
